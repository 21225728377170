import React from "react"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"

import { SectionHeading } from "../components/headings/index"

const CookiesPage = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <Seo title="Piškotki" lang="sl" />
      <section className="max-w-screen-md mx-auto px-4 py-10 sm:py-16">
        <SectionHeading title="cookies" />
        <div>
          <h2 className="font-bold text-xl mb-4">Kaj so piškotki?</h2>
          <p className="mb-4">
            Piškotki so majhne tekstovne datoteke, ki se zapišejo na vaš
            računalnik, ko obiščete spletno stran. Služijo za zagotavljanje
            sledljivosti in ko se naslednjič vrnete na stran preko istega
            brskalnika, lahko le ta preveri ali je piškotek prisoten in uporabi
            podatke iz piškotka ter jih pošlje nazaj strani. Piškotki nam
            omogočajo, da prepoznamo ali nas je uporabnik že predhodno obiskal
            in v nekaterih primerih temu lahko prilagodimo prikazano vsebino.
          </p>
          <h2 className="font-bold text-xl mb-4">
            Upravljanje in brisanje piškotkov
          </h2>
          <p className="mb-4">
            V primeru, da se odločite spremeniti način kako brskalnik uporablja
            piškotke, lahko to storite s spreminjanjem nastavitev brskalnika.
            Večina brskalnikov omogoča, da zavrnete ali sprejmete vse piškotke,
            sprejmete samo določene piškotke ali pa vas vsakič vpraša ali
            dovolite piškotke za določeno stran ali ne. Brisanje piškotkov se
            razlikuje glede na brskalnik, ki ga uporabljate. Za dodatna navodila
            kako to storiti, vam predlagamo, da se poslužite pomoči v
            brskalniku, kjer boste našli navodila za upravljanje piškotkov
            vašega brskalnika.
          </p>
            <h2 className="font-bold text-xl mb-4">
            Piškotki, ki jih uporabljamo
          </h2>
          <p className="mb-4">
         Na naši spletni strani uporabljamo različne kategorije piškotkov, ki jih delimo na:
         </p>
          <ul className='mb-4 list-disc pl-5'>
              <li>nujne piškotke </li>
              <li>analitične piškotke</li>
              <li>funkcionalne piškotke</li>
          </ul>
          <p className="mb-4">
            Več informacij o tem kako uporabljamo piškotke najdete{" "}
            <a
              href="https://www.ip-rs.si/varstvo-osebnih-podatkov/informacijske-tehnologije-in-osebni-podatki/pi%C5%A1kotki-odgovori-na-pogosta-vpra%C5%A1anja"
              target="_blank"
              rel="noreferrer"
            >
              na tej povezavi.
            </a>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default CookiesPage
